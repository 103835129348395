import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Parser from "html-react-parser"
import styled from "styled-components"
import { Typography, Select } from "@material-ui/core"
import { Magnify } from "mdi-material-ui"

// import app components
import SidebarContent from "components/sidebarContent"
import BackgroundImage from "components/backgroundImage"
import { getUrlPath } from "utils/getUrlPath"
import * as theme from "theme"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

const ArchitectBlogPosts = (props) => {
  const [category, setCategory] = useState("all")

  const {
    data: {
      wp: {
        themeSettings: {
          siteOptions: { defaultHeroImage }
        }
      },
      allWpPost,
      wpPage: {
        title,
        uri,
        templateArchitectBlog: { selectCategory }
      }
    }
  } = props

  const filteredPosts = allWpPost.nodes.filter((post) =>
    post.categories.nodes.some((postCategory) =>
      selectCategory.some((selectCat) => selectCat.databaseId === postCategory.databaseId)
    )
  )

  const posts =
    category !== "all"
      ? filteredPosts.filter((o) => o.categories.nodes.findIndex((p) => p.databaseId === +category) > -1)
      : filteredPosts

  return (
    <SidebarContent image={defaultHeroImage} title={title} slug={getUrlPath(uri)} hideSidebar={true} edgesSize={"md"}>
      <SelectContainer>
        <Label>Category</Label>
        <Select native value={category} onChange={(e) => setCategory(e.target.value)}>
          {/* eslint-disable jsx-a11y/control-has-associated-label */}
          <option value="all" children={"All"} />

          {selectCategory &&
            selectCategory.map((o) => <option key={o.databaseId} value={o.databaseId} children={o.name} />)}
        </Select>
      </SelectContainer>

      <PostsContainer>
        {posts.length > 0 ? (
          posts.map((o, i) => {
            const isEven = i % 2

            const dateArray = o.date.split("-")
            const year = dateArray[0]
            const month = months[parseInt(dateArray[1]) - 1]

            return (
              <Post key={o.id}>
                <ImageContainer isEven={isEven} to={getUrlPath(o.uri)}>
                  {o.post?.thumbnailId && (
                    <BackgroundImage image={o.post.thumbnailId} alt={o.post.thumbnailId.altText} />
                  )}
                  <Magnify />
                  <SrOnly>{o.title + " blog article"}</SrOnly>
                </ImageContainer>

                <PostContent isEven={isEven}>
                  <Date variant="caption" children={`${month} ${year}`} />
                  <TitleLink to={getUrlPath(o.uri)}>
                    <Title variant="h2" children={Parser(o.title)} />
                  </TitleLink>
                </PostContent>
              </Post>
            )
          })
        ) : (
          <NoPosts variant="h3" children={`Nothing here...`} />
        )}
      </PostsContainer>
    </SidebarContent>
  )
}

const SelectContainer = styled.div`
  && {
    width: 100%;
    margin-bottom: 20px;

    @media (min-width: 640px) {
      max-width: 300px;
      margin-bottom: 0;
    }

    .MuiInput-root {
      width: 100%;
    }

    .MuiSelect-select {
      display: flex;
      align-items: center;
      height: 42px;
      width: 100%;
      background: #f2f2f2;
      padding: 0 24px 0 10px;
      font-size: 16px;
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      content: none;
    }
  }
`

const NoPosts = styled(Typography)`
  && {
    margin: 50px 0;
  }
`

const Label = styled(Typography)`
  && {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
`

const PostsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 300px;
`

const Post = styled.div`
  width: 100%;
  margin-bottom: 4vw;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const ImageContainer = styled(({ isEven, ...rest }) => <Link {...rest} />)`
  width: 100%;
  height: 200px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 0px;
  order: ${(props) => (props.isEven ? 1 : 3)};
  text-decoration: none;

  @media (min-width: 640px) {
    width: calc(50% + 20px);
    height: 300px;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    transition: ease all 0.3s;
    opacity: 0;
  }

  svg {
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: ease all 0.3s;
    opacity: 0;
    width: 32px;
    height: 32px;

    path {
      fill: #fff;
    }
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 25px 0px;

    &:after,
    svg {
      opacity: 1;
    }
  }
`

const PostContent = styled.div`
  width: 100%;
  padding: 20px 0;
  order: 4;

  @media (min-width: 640px) {
    width: calc(50% - 20px);
    order: 2;
    padding: ${(props) => (props.isEven ? "20px 0 20px 20px" : "20px 20px 20px 0")};
  }
`

const Date = styled(Typography)`
  display: inline-block;
  margin-bottom: 10px;
  background: ${theme.colors.primary};
  padding: 4px 30px 2px;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
`

const SrOnly = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`

const TitleLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${theme.colors.primary};
  }
`

const Title = styled(Typography)`
  width: 100%;
`

export const CollectionQuery = graphql`
  query BlogTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      acfModules {
        hideSidebar
        hideSearch
      }
      templateArchitectBlog {
        selectCategory {
          ... on WpCategory {
            language {
              id
              slug
            }
            name
            databaseId
          }
        }
      }
    }
    allWpPost(limit: 1000, sort: { order: DESC, fields: date }) {
      nodes {
        id
        title
        uri
        date
        categories {
          nodes {
            name
            databaseId
          }
        }
        post {
          thumbnailId {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    # allWpCategory {
    #   nodes {
    #     language {
    #       id
    #       slug
    #     }
    #     name
    #     databaseId
    #   }
    # }
    wp {
      themeSettings {
        siteOptions {
          defaultHeroImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

export default ArchitectBlogPosts
